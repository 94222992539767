import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ImageMap from '../components/ImageMap';

import '../assets/styles/pages/our-offices-page.scss';

// import imageAll from '../assets/images/photos/our-offices/all.png';
// import imageAllActive from '../assets/images/photos/our-offices/all-active.png';
// import imageAmerica from '../assets/images/photos/our-offices/america.png';
// import imageAmericaActive from '../assets/images/photos/our-offices/america-active.png';
import imageEurope from '../assets/images/photos/our-offices/europe.png';
import imageEuropeActive from '../assets/images/photos/our-offices/europe-active.png';
import imageMiddleEast from '../assets/images/photos/our-offices/middle-east.png';
import imageMiddleEastActive from '../assets/images/photos/our-offices/middle-east-active.png';

const tabValue = {
  // all: 'all',
  europe: 'europe',
  middleEast: 'middle_east',
  // america: 'america',
};

const tabConfig = [
  // {
  //   label: 'All',
  //   value: tabValue.all,
  //   labelInactive: imageAll,
  //   labelActive: imageAllActive,
  //   width: '32px',
  // },
  // {
  //   label: 'Americas',
  //   value: tabValue.america,
  //   labelInactive: imageAmerica,
  //   labelActive: imageAmericaActive,
  //   width: '107px',
  // },
  {
    label: 'Europe',
    value: tabValue.europe,
    labelInactive: imageEurope,
    labelActive: imageEuropeActive,
    width: '80px',
  },
  {
    label: 'Middle East',
    value: tabValue.middleEast,
    labelInactive: imageMiddleEast,
    labelActive: imageMiddleEastActive,
    width: '130px',
  },
];

const OurOffices = ({ data }) => {
  const prismicData = data.prismic.allOur_offices_pages.edges[0].node;
  console.log('debug-prismicData :: ', prismicData);
  const title = RichText.asText(prismicData.title_main);
  const searchTitle = RichText.asText(prismicData.title_search);

  const [tab, setTab] = useState(tabValue.middleEast);
  const [offices, setOffices] = useState(null);

  const filterOfficesByTabValue = () => {
    if (tab === tabValue.all) {
      setOffices(prismicData.offices);
    } else {
      const filtered = prismicData.offices.filter((o) => o.region[0].text === tab && !!o.img);
      setOffices(filtered);
    }
  };

  useEffect(() => {
    filterOfficesByTabValue();
  }, []);

  useEffect(() => {
    filterOfficesByTabValue();
  }, [prismicData, tab]);

  console.log('offices', offices);

  return (
    <Layout>
      <SEO title="Contact us" />
      <div className="our-offices-page">
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-md-12 col-lg-12 map-block" // mx-auto
            >
              <div className="c-h1 title">{title}</div>
              <ImageMap map={prismicData.title_imageSharp.childImageSharp.fluid} />
            </div>
          </div>
        </div>

        <div className="second-block">
          <div className="container ">
            <div className="row">
              <div className="col-12 col-md-11 col-lg-8 mx-auto first-section__top">
                <div className="c-h1 title search">{searchTitle}</div>

                <div className="tab-container">
                  {tabConfig.map((t) => (
                    <div
                      className={`single-tab ${t.value === tab && 'active'}`}
                      role="button"
                      tabIndex={t.value}
                      onClick={() => setTab(t.value)}
                      onKeyDown={() => setTab(t.value)}
                    >
                      <div
                        style={{
                          width: t.width,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          className="address-img"
                          src={tab === t.value ? t.labelActive : t.labelInactive}
                          alt={t.label}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {offices && offices.map((office) => <AddressBlock prismicData={office} />)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const AddressBlock = ({
  prismicData: {
    // country,
    // prefix,
    // suffix,
    // company_name,
    // address,
    // email,
    // is_head_quarter,
    img,
  },
}) => (
  <div className="col-12 col-md-6 col-lg-4 address-block">
    {img && img.url && <img className="address-img" src={img.url} alt="address" />}
  </div>
);

export default OurOffices;

export const queryOffices = graphql`
  query {
    prismic {
      allOur_offices_pages {
        edges {
          node {
            title_search
            title_main
            title_image
            title_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1220, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            offices {
              address
              company_name
              country
              email
              is_head_quarter
              prefix
              region
              suffix
              img
            }
          }
        }
      }
    }
  }
`;
